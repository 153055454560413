import { graphql } from 'gatsby';
import StatutePage from '../views/legal/statute';

export default StatutePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    statuteObjects: allContentfulStatute {
      edges {
        node {
          language
          content {
            raw
          }
          node_locale
        }
      }
    }
  }
`;
