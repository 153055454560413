import * as React from 'react';
import Layout from '../../components/layout/layout';
import './style/legal.css';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import getProperLangDocument from './components/proper-lang';
import Seo from '../../components/seo';
import getCurrentTranslations from '../../components/contentful-translator';
import ogImage from '../../images/seo/SEO_fundacja.jpg';

const StatutePage = ({ data }) => {
  const { language } = useI18next();
  const { t } = useTranslation();
  const statuteObjects = getCurrentTranslations(
    data.statuteObjects.edges,
    language
  );

  const properStatute = getProperLangDocument(statuteObjects, language);

  return (
    <Layout>
      <Seo
        title={t('statute.page-title')}
        description={t('statute.page-description')}
        ogImage={ogImage}
      />
      <section className="page-section container legal" id="statute">
        {renderRichText(properStatute)}
      </section>
    </Layout>
  );
};

export default StatutePage;
